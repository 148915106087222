import { gql, useQuery } from 'urql';
import { IOrganizer } from '@features/organizer/model/types';

export const GET_ORGANIZERS_QUERY = gql`
  query GetOrganizersByEventId($eventId: Int!) {
    getOrganizersByEventId(eventId: $eventId) {
      id
      uuid
      email
      login
      position
      phone
      firstName
      lastName
      avatar
      confirmed
      sortNumber
      active
      surname
      source
      isIndividual
      departamentUuid
      createdAt
      updatedAt
    }
  }
`;

export interface GetOrganizersResponse {
  getOrganizersByEventId: IOrganizer[];
}

export const useGetOrganizers = (eventId: number | undefined) => {
  const [result, reexecuteQuery] = useQuery<GetOrganizersResponse>({
    query: GET_ORGANIZERS_QUERY,
    variables: { eventId },
    pause: !eventId,
  });

  return {
    data: result.data,
    fetching: result.fetching,
    error: result.error,
    refetch: reexecuteQuery,
  };
};
